body {
    background-color: #f1fff0;
    font-family: 'Sarpanch';
}

#loginContainer{
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}